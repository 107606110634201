<div class="page">
  <div style="margin-left: 25px">
    <span
      ><strong>Personal Finance Tracker (PFT)</strong> is a simple tool to
      understand your income and expenses and provide you with a holistic
      picture of your finances.
    </span>

    <p style="margin-top: 20px">
      Please note that PFT will not store any of the information you provide.
      The downside is that every time you visit, you will have to provide your
      data again. Alternatively, if you simply want to explore, PFT will give
      you an option to use a demo data along the way.
    </p>
  </div>
  <app-file-upload
    heading="1. Would you like to upload your bank transactions?"
    (uploadedData)="useUploadedTransactionsData($event)"
    [defaultDataStr]="defaultTransactionDataStr"
    (defaultData)="useDefaultTransactionsData()"
    dataCaption="Notice that every transaction consists of a date, a description, and an amount. Amounts that are greater than zero are income and those that are less are expenses.
      If you'd like to upload your data, make sure to follow the format shown below. Each transaction must follow this format so that PFT can understand the data. Any additional headers will be ignored."
  ></app-file-upload>

  <app-file-upload
    *ngIf="uploadedTransactionsData == true"
    heading="2. Would you like to upload a list of categories and keywords?"
    subHeading="PFT will use this to categorize your expenses into different buckets."
    (uploadedData)="useUploadedCategoryData($event)"
    [defaultDataStr]="defaultCategoryString"
    (defaultData)="useDefaultCategoryData()"
    dataCaption="The first value for every row is a category followed by the keywords
      associated to it. If you'd like to upload your own .csv file, make sure to follow the format shown
      below."
  ></app-file-upload>

  <div style="margin-left: 30px" *ngIf="firstChartData.length > 0">
    <div
      *ngIf="
        (uploadedTransactionsData == true &&
          uploadCategoriesData != undefined) ||
        (uploadedTransactionsData == true && uploadCategoriesData == false)
      "
    >
      <h2 style="margin-bottom: 30px; margin-top: 30px">
        3. Nicely done! Here's what I have for you.
      </h2>
      <p style="margin-top: -20px; margin-left: 30px; margin-right: 20px">
        The interactive visualizations shown below represent a picture of your
        finances for each month. You can click through the legend and see how it
        changes your charts!
      </p>
      <p style="margin-top: -10px; margin-left: 30px; margin-right: 20px">
        Keep in mind that these are noncummulative&#8212;each monthly data is
        completely independent from each other.
      </p>

      <app-chart
        type="line"
        [title]="firstChartTitle"
        [data]="firstChartData"
        [labels]="labels"
        [colors]="firstChartColors"
      ></app-chart>

      <app-chart
        type="line"
        title="Expenses By Category"
        [data]="secondChartData"
        [labels]="labels"
        [colors]="secondChartColors"
      ></app-chart>
    </div>
  </div>
</div>
