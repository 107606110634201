export enum ExpensesCategory {
  GIVE = 'give',
  BILLS = 'bills',
  GROCERY = 'grocery',
  ENTERTAINMENT = 'entertainment',
  LEGAL = 'legal',
  RESTAURANT = 'restaurant',
  SAVINGS = 'savings',
  OTHER = 'other',
}
