<div id="chart-container">
  <h4 style="text-align: center">{{ title }}</h4>
  <canvas
    baseChart
    height="22vh"
    width="100vw"
    chartType="line"
    [datasets]="data"
    [labels]="labels"
    [options]="options"
    [legend]="legend"
    [colors]="colors"
  ></canvas>
</div>
