<div style="margin-left: 50px">
  <h2 style="margin-left: -28px">{{ heading }}</h2>
  <p>
    {{ subHeading }} If you do not want to upload your information or would like to see the format, click
    <span> <em>Demo</em> </span>.
  </p>
  <button
    [disabled]="disabled"
    class="btn"
    mat-raised-button
    (click)="file.click()"
  >
    Upload File
  </button>
  <button
    [disabled]="disabled"
    class="btn"
    mat-raised-button
    (click)="useDefaultData()"
  >
    Demo
  </button>
  <em *ngIf="disabled" style="margin-left: 10px; font-size: smaller">
    If you'd like to upload a file, reload the page and start again.
  </em>
  <input
    [disabled]="disabled"
    id="file-upload"
    #file
    style="visibility: hidden"
    class="form-control"
    type="file"
    accept=".csv"
    (change)="onChange($event)"
  />
  <div *ngIf="step == 2">
    <strong *ngIf="!disabled">{{ getConfirmationQuestion() }}</strong>
    <p>{{ dataCaption }}</p>
    <button
      *ngIf="!disabled"
      class="btn"
      mat-raised-button
      (click)="emitBtn.click()"
    >
      Let's use this data.
    </button>
    <button #emitBtn style="visibility: hidden" (click)="emitData()"></button>
    <pre id="csv">
        {{ dataString }}
    </pre>
  </div>
</div>
